import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TransformIcon from "@mui/icons-material/Transform";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const handleUpload = async (file: File) => {
  if (file) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      // You can write the URL of your server or any other endpoint used for file upload
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}format/resume`,
        {
          method: "POST",
          body: formData,
          headers: {
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true",
          },
        }
      );

      const { data } = await result.json();
      const byteArray = new Uint8Array(data);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      document.body.appendChild(a);
      //   a. = "display: none";
      a.href = url;
      a.download = `${file.name.replace(".pdf", "")}_TalentQuest.docx`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      window.alert(error);
    }
  }
};

export default function InputFileUpload() {
  const [file, setFile] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Button
        component="label"
        role={undefined}
        disabled={loading}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        {"Upload PDF"}
        <VisuallyHiddenInput
          disabled={loading}
          type="file"
          accept="application/pdf"
          onChange={(event) => {
            if (event.target.files) {
              setFile(event.target.files[0]);
            }
          }}
        />
      </Button>
      {file && <h2>{file.name}</h2>}
      {file && (
        <Button
          variant="contained"
          tabIndex={-1}
          startIcon={<TransformIcon />}
          color="success"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await handleUpload(file);
            setLoading(false);
          }}
        >
          {loading ? "幫緊你幫緊你..." : "Format"}
        </Button>
      )}
    </div>
  );
}
